import React from "react";
import {Link, Redirect} from "react-router-dom";

import Unauthorized from "../pages/Errors/Unauthorized";
import Offlinepage from "../pages/Errors/Offlinepage";
import Error500 from "../pages/Errors/Error500";

import RedirectPage from "../pages/Authentication/RedirectPage";

import Login from "../pages/Authentication/Login";
import Dashboard from "../pages/Dashboard/Dashboard";

import { Utils } from "custom";

import AdministratorList from "../pages/Administrator/AdministratorList";

import ConsulentList from "../pages/Consulent/ConsulentList";

import LeadList from "../pages/Lead/LeadList";
import RemarketingList from "../pages/Lead/RemarketingList";

import ModuleList from "../pages/Module/ModuleList";

import TeachearsList from "../pages/Teacher/TeachersList";

import ClientList from "../pages/Client/ClientList";
import ClientGest from "../pages/Client/ClientGest";
import ClientDetail from "../pages/Client/ClientDetail";

import CourseList from "../pages/Course/CourseList";
import CourseDetail from "../pages/Course/CourseDetail";

import SellList from "../pages/Sell/SellList";
import SellCreate from "../pages/Sell/SellCreate";
import SellGest from "../pages/Sell/SellGest";

import PaymentList from "../pages/Payment/PaymentList";

import LeadDetail from "../pages/Lead/LeadDetail";

import Available from "../pages/Available/Available";
import TooManySessionPage from "../pages/Errors/TooManySessionPage";
import Profile from "../pages/Profile/Profile";

import ClassRoomList from "../pages/ClassRoom/ClassRoomList";
import ClassRoomTableClient from "../pages/ClassRoom/ClassRoomTableClient";
import ClassRoomAvailable from "../pages/ClassRoom/ClassRoomAvailable";
import ClassRoomDetail from "../pages/ClassRoom/ClassroomEdit";
import Assistance from "../pages/Assistance/Assistance";

import ClassRoomMain from "../pages/ClassRoom/ClassRoomMain";

import ClassRoomMainInside from "../pages/ClassRoom/ClassRoomMainInside";

import ClassroomTrial from "../pages/ClassroomTrial/ClassroomTrialList";
import ClassroomEdit from "../pages/ClassRoom/ClassroomEdit";

import NetworkDash from "../pages/Network/Main";



const authProtectedRoutes = [
  { path: "/dashboard", auth: ["ADMIN", "CONSULENT"], component: Dashboard },
  { path: "/admin", auth: ["ADMIN"], component: AdministratorList },
  { path: "/consulent", auth: ["ADMIN"], component: ConsulentList },

  { path: "/module", auth: ["ADMIN"], component: ModuleList },

  { path: "/lead", auth: ["ADMIN", "CONSULENT"], component: LeadList },
  { path: "/remarketing", auth: ["CONSULENT"], component: RemarketingList },
  { path: "/lead/detail/:id", auth: ["ADMIN", "CONSULENT"], component: LeadDetail },

  { path: "/teachers", auth: ["ADMIN"], component: TeachearsList },
  { path: "/available", auth: ["TEACHER"], component: Available },

  { path: "/client", auth: ["ADMIN", "CONSULENT"], component: ClientList },
  { path: "/client/add", auth: ["ADMIN", "CONSULENT"], component: ClientGest },
  { path: "/client/edit/:id", auth: ["ADMIN", "CONSULENT"], component: ClientGest },
  { path: "/client/:id", auth: ["ADMIN", "CONSULENT"], component: ClientDetail },

  { path: "/course", auth: ["ADMIN", "TEACHER", "CONSULENT", "CLIENT"], component: CourseList },
  { path: "/course/:id", auth: ["ADMIN", "TEACHER", "CONSULENT", "CLIENT"], component: CourseDetail },

  { path: "/sell", auth: ["ADMIN", "CONSULENT"], component: SellList },
  { path: "/sell/:id", auth: ["ADMIN", "CONSULENT"], component: SellGest },
  { path: "/sell/create", auth: ["ADMIN", "CONSULENT"], component: SellCreate },

  { path: "/profile", auth: ["ADMIN", "TEACHER", "CONSULENT", "CLIENT"], component: Profile },
  { path: "/classroom", auth: ["ADMIN", "CONSULENT"], component: ClassRoomList },
  { path: "/classroom/list", auth: ["TEACHER", "CLIENT"], component: ClassRoomTableClient },
  { path: "/classroom/trial", auth: ["ADMIN", "CONSULENT", "TEACHER"], component: ClassroomTrial },
  { path: "/classroom/create", auth: ["ADMIN", "TEACHER"], component: ClassRoomDetail },
  { path: "/classroom/available", auth: ["ADMIN"], component: ClassRoomAvailable },
  { path: "/classroom/edit/:id", auth: ["ADMIN"], component: ClassroomEdit },

  { path: "/classroom/:id", auth: ["ADMIN", "TEACHER", "CONSULENT"], component: ClassRoomMain },

  { path: "/classroom/inside/:id", auth: ["ADMIN", "TEACHER", "CONSULENT", "CLIENT"], component: ClassRoomMainInside },

  { path: "/payment", auth: ["ADMIN", "CONSULENT"], component: PaymentList },

  { path: "/network", auth: ["ADMIN", "CONSULENT", "CLIENT", "TEACHER"], component: NetworkDash },

  {
    path: "/",
    auth: ["ADMIN", "TEACHER", "CONSULENT", "CLIENT"],
    exact: true,
    component: () => <Redirect to={"/course"} />,
  },
  { path: "/assistance", auth: ["ADMIN", "TEACHER", "CONSULENT", "CLIENT"], component: Assistance },
];

const publicRoutes = [
  { path: "/redirect", component: RedirectPage },
  { path: "/login", component: Login },
  { path: "/offline", component: Offlinepage },
  { path: "/unauthorized", component: Unauthorized },
  { path: "/server/error", component: Error500 },
  { path: "/session/error", component: TooManySessionPage }

];

export { authProtectedRoutes, publicRoutes };
