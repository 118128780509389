import _GeoAPI from './GeoAPI';
import _AuthAPI from './AuthAPI';
import _UserAPI from './UserAPI';
import _AdminAPI from './AdminAPI';
import _ConsulentAPI from './ConsulentAPI';
import _LeadAPI from './LeadAPI';
import _ModuleAPI from './ModuleAPI';
import _LeadLogAPI from './LeadLogAPI';
import _LeadReminderAPI from './ReminderAPI';
import _TeacherAPI from './TeacherAPI';
import _ClientAPI from './ClientAPI';
import _CourseAPI from './CourseAPI';
import _LessonAPI from './LessonAPI';
import _SellAPI from './SellAPI';
import _StreamingAPI from './StreamingAPI';
import _AvailableAPI from './AvailableAPI';
import _LevelAPI from './LevelAPI';
import _ClassRoomAPI from './ClassRoomAPI';
import _ClassRoomInsideAPI from './ClassRoomInsideAPI';
import _PaymentAPI from './PaymentAPI';
import _ClassRoomTrialAPI from './ClassRoomTrialAPI';
import _DashboardAPI from './DashboardAPI';
import _ReporterAPI from './ReporterAPI';
import _ReportAPI from './ReportAPI';
import _NetworkAPI from './NetworkAPI';


export const GeoAPI = _GeoAPI;
export const AuthAPI = _AuthAPI;
export const UserAPI = _UserAPI;
export const AdminAPI = _AdminAPI;
export const ConsulentAPI = _ConsulentAPI;
export const LeadAPI = _LeadAPI;
export const ModuleAPI = _ModuleAPI;
export const LeadLogAPI = _LeadLogAPI;
export const LeadReminderAPI = _LeadReminderAPI;
export const TeacherAPI = _TeacherAPI;
export const ClientAPI = _ClientAPI;
export const CourseAPI = _CourseAPI;
export const LessonAPI = _LessonAPI;
export const SellAPI = _SellAPI;
export const StreamingAPI = _StreamingAPI;
export const AvailableAPI = _AvailableAPI;
export const LevelAPI = _LevelAPI;
export const ClassRoomAPI = _ClassRoomAPI;
export const ClassRoomInsideAPI = _ClassRoomInsideAPI;
export const PaymentAPI = _PaymentAPI;
export const ClassRoomTrialAPI = _ClassRoomTrialAPI;
export const DashboardAPI = _DashboardAPI;
export const ReporterAPI = _ReporterAPI;
export const ReportAPI = _ReportAPI;
export const NetworkAPI = _NetworkAPI;


