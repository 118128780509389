import React, {useEffect, useState} from 'react';
import {
    Alert,
    Badge,
    Card,
    CardBody,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink,
    Row, Spinner, TabContent, TabPane, UncontrolledDropdown
} from 'reactstrap';

import {ClassRoomAPI, LeadAPI, PaymentAPI} from "api";
import {Link, useParams, useHistory} from "react-router-dom";
import {
    Loading,
    RevertClassroom,
    Utils
} from "custom";

import {_, Grid} from "gridjs-react";

const moment = require("moment");

const StudentInside = ({groups, onResolve, classroomId}) => {
    const history = useHistory()

    const [loading, setLoading] = useState(true);
    const [classroom, setClassroom] = useState(null);

    const [error, setError] = useState(false);

    const isAdmin = (Utils.getUserData().role === "ADMIN");
    const isTeacher = (Utils.getUserData().role === "TEACHER");

    const {id} = useParams();

    useEffect(() => {

    }, []);

    const showDetailAttendances = (id_student) => {

    }



    return (
        <React.Fragment>
            {/*<Row className="g-4 mb-3">*/}
            {/*    <div className="col-sm">*/}
            {/*        <div className="d-flex">*/}
            {/*            <div className="search-box me-2">*/}
            {/*                <input type="text" className="form-control" placeholder="Cerca corsista..." />*/}
            {/*                <i className="ri-search-line search-icon"></i>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Row>*/}

            {groups.map((g, index) => {
                return <div className="team-list list-view-filter pt-2 pb-2" key={index}>
                    <h5>Gruppo ore <b>{g.slot.split(" - ")[0]}</b></h5>
                    {g.partecipants.map((p, index2) => {
                        return <Card className="team-box" key={index2}>
                            <CardBody className="px-4">
                                <Row className="align-items-center team-row">
                                    {/*<div className="col team-settings">*/}
                                    {/*    <Row className="align-items-center">*/}
                                    {/*        <UncontrolledDropdown className="col text-end">*/}
                                    {/*            <DropdownToggle tag="a" role="button">*/}
                                    {/*                <i className="ri-more-fill fs-17"></i>*/}
                                    {/*            </DropdownToggle>*/}
                                    {/*            <DropdownMenu className="dropdown-menu-end">*/}
                                    {/*                <li><DropdownItem><i className="ri-eye-fill text-muted me-2 align-bottom"></i>Modifica</DropdownItem></li>*/}
                                    {/*            </DropdownMenu>*/}
                                    {/*        </UncontrolledDropdown>*/}
                                    {/*    </Row>*/}
                                    {/*</div>*/}
                                    <Col lg={4}>
                                        <div className="team-profile-img">
                                            <div className="avatar-lg img-thumbnail rounded-circle">
                                                <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                    {p.firstname[0]}{p.lastname[0]}
                                                </div>
                                            </div>
                                            <div className="team-content">
                                                <h5 className="fs-16 mb-1">{p.firstname} {p.lastname}</h5>
                                                {(isAdmin || isTeacher) ? <span>
                                                    {(p.email) ? <p className="text-muted mb-0">{p.email}</p> : <p className="text-danger mb-0"><b>Email mancante</b></p>}
                                                    {(p.cell) ? <span>
                                                        <p className="text-muted mb-0">{p.cell}</p>
                                                        <a href={"https://api.whatsapp.com/send?phone=" + p.cell.slice(1)}><Badge
                                                            style={{fontSize: "12px"}} color="success"><i
                                                            className="mdi mdi-whatsapp"></i> Whatsapp</Badge>
                                                    </a>&nbsp;&nbsp;
                                                        <a href={"tel:" + p.cell}><Badge
                                                            style={{fontSize: "12px"}} color="primary"><i
                                                            className="mdi mdi-phone"></i> Chiama</Badge></a>
                                                    </span> : <p className="text-danger mb-0">Cellulare mancante</p>}
                                                </span> : null}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <Row className="text-muted text-center">
                                            <Col xs={12} className="border-end border-end-dashed">
                                                <h5 className="mb-1">{p.attendances}</h5>
                                                <p className="text-muted mb-0">Presenze</p>
                                            </Col>
                                            {/*<Col xs={6}>*/}
                                                {/*<span className="btn btn-sm btn-light view-btn" onClick={() => showDetailAttendances(p.id)}>Dettagli presenze</span>*/}
                                            {/*</Col>*/}
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    })}
                    {(g.partecipants.length === 0) ? <Alert color="dark">Nessun corsista trovato</Alert> : null}
                </div>
            })}
        </React.Fragment>
    );
};

export default StudentInside;
