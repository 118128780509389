import React, {useEffect, useState} from 'react';
import {
    Alert,
    Badge, Button,
    Card,
    CardBody, CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink,
    Row, Spinner, TabContent, TabPane, UncontrolledDropdown
} from 'reactstrap';

import {ClassRoomAPI, ClassRoomInsideAPI, LeadAPI, PaymentAPI} from "api";
import {Link, useParams, useHistory} from "react-router-dom";
import {
    DeleteLeadLog, DeletePost,
    EditLeadLog, EditPost,
    Loading,
    RevertClassroom,
    Utils
} from "custom";

import SimpleBar from "simplebar-react";

const moment = require("moment");

const BoardInside = ({posts, classroomId, onResolve}) => {
    const history = useHistory()

    const [loadingButton, setLoadingButton] = useState(false);
    const [classroom, setClassroom] = useState(null);

    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");

    const isAdmin = (Utils.getUserData().role === "ADMIN");
    const isTeacher = (Utils.getUserData().role === "TEACHER");

    const {id} = useParams();

    useEffect(() => {
        getDetailClassroom();
    }, []);


    const getDetailClassroom = () => {
        // ClassRoomAPI.detailClassroom(id).then(async (res) => {
        //     // if(!isTeacher) {
        //     //     let wList = await ClassRoomAPI.listWaiting();
        //     //     wList = wList.filter((w) => w.level_id === res.level.id);
        //     //     setWaitingList(() => wList.reverse());
        //     // }
        //
        //     setClassroom(() => res)
        //     setLoading(false)
        //     setError(false);
        //
        // }).catch((err) => {
        //     setError(true);
        //     console.error(err);
        // })
    }


    const sendMessage = () => {

        if (message === "")
            return;

        setLoadingButton(true)

        ClassRoomInsideAPI.postAdd({
            id_classroom: classroomId,
            message: message
        }).then(() => {
            onResolve();
            setLoadingButton(false)
            setError(false);
            setMessage("")
        }).catch((err) => {
            setError(true);
            console.error(err);
        })
    }


    if (error) {
        return <React.Fragment>
            <div className="page-content">
                <Alert color="danger" >Si è verificato un errore durante il recupero del dettaglio classe.</Alert>
            </div>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <Row>
                <Col xl={9} lg={8}>
                    <Card>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Commenti Bacheca</h4>
                        </CardHeader>

                        <CardBody>
                            <SimpleBar style={{ height: "300px" }} className="px-3 mx-n3 mb-2">
                                {posts.map((p, i) => {
                                    return <div key={i} className="d-flex mb-4">
                                        <div className="team-profile-img">
                                            <div className="avatar-sm img-thumbnail rounded-circle">
                                                {(p.User.role === "CLIENT") ? <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                    {p.User.firstname[0]}{p.User.lastname[0]}
                                                </div> : <div className="avatar-title bg-white rounded-circle">
                                                    <img src={require("../../../assets/images/avatar-cltv.png")} alt="" className="avatar-sm" />
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h5 className="fs-13">{(p.User.role === "TEACHER") ? <i className="mdi mdi-school align-bottom me-1"></i> : null} {p.User.firstname} {p.User.lastname} <small className="text-muted ms-2">{moment(p.createdAt).format("DD MMM YYYY HH:mm")}</small></h5>
                                            <p className="text-muted">{p.message}</p>
                                        </div>
                                        {(p.User.id === Utils.getUserData().id || isAdmin)
                                            ?
                                            <div>
                                                <EditPost preset={p} onResolve={onResolve} label='Modifica Commento'/>
                                                &nbsp;&nbsp;&nbsp;
                                                <DeletePost id={p.id} onResolve={onResolve}/>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                })}
                            </SimpleBar>
                            <form className="mt-4">
                                <Row className="g-3">
                                    <Col xs={12} >
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label text-body">Lascia un Commento</label>
                                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="form-control bg-light border-light" id="exampleFormControlTextarea1" rows="3" placeholder="Inserisci il tuo messaggio..."></textarea>
                                    </Col>
                                    <Col xs={12} className="text-end">
                                        {/*{(Utils.getUserData().role !== "CLIENT") ? <button  type="button" className="btn btn-ghost-secondary btn-icon waves-effect me-1 shadow-none"><i className="mdi mdi-attachment fs-20"></i></button> : null}*/}
                                        <button disabled={(message === "")} onClick={sendMessage} type="button" className="btn btn-success">{(loadingButton) ? <Spinner /> : <b>Invia</b>}</button>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>

                {/*<Col xl={3} lg={4}>*/}


                {/*    <Card>*/}
                {/*        <CardHeader className="align-items-center d-flex border-bottom-dashed">*/}
                {/*            <h4 className="card-title mb-0 flex-grow-1">Prossima Lezione</h4>*/}
                {/*        </CardHeader>*/}

                {/*        <CardBody>*/}
                {/*            <div className="vstack gap-2">*/}
                {/*                <div className="border rounded border-dashed p-2">*/}
                {/*                    <div className="d-flex align-items-center">*/}
                {/*                        <div className="flex-shrink-0 me-3">*/}
                {/*                            <div className="avatar-sm">*/}
                {/*                                <div className="avatar-title bg-light text-secondary rounded fs-24 shadow">*/}
                {/*                                    <i className="mdi mdi-calendar-clock"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="flex-grow-1 overflow-hidden">*/}
                {/*                            <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block">15 Dicembre 2024</Link></h5>*/}
                {/*                            <div>Ore 18:00</div>*/}
                {/*                            <Button size="sm" color="secondary" className="mt-2"><i className="mdi mdi-video-check"></i> Accedi alla lezione</Button>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
            </Row>
        </React.Fragment>
    );
};

export default BoardInside;
