let server = process.env.REACT_APP_API_URL;

let frontendRoute = process.env.REACT_APP_FRONTEND_URL;

let productionMode = (process.env.REACT_APP_NODE_ENV === "production"); // valorizzare in TRUE per produzione
let versionApp = process.env.REACT_APP_VERSION; //Valorizzare per cambiare versione applicazione
let emailAssistance = process.env.REACT_APP_API_EMAIL_ASSISTANCE; //email assistenza
let route = {
  test: '/test',

  // dashboard
  dashboard: '/dashboard',
  dashboardCounters: "/dashboard/counters",
  dashboardConsulents: "/dashboard/consulents",
  dashboardModules: "/dashboard/modules",

  // geo service
  autocompleteGeo: '/geo/autocomplete',
  detailGeo: '/geo/detail',
  geoGps: '/geo/gps',

  // auth
  checkToken: '/token/check',
  permission: '/auth/permission',
  login: '/auth/signin',
  credentialEdit: '/auth/changepsw',
  resetPassword: '/auth/forgotpsw',
  completeResetPassword: '/auth/forgotpsw/reset',
  verifyOTP: '/auth/verifyotp',
  resendOTP: '/auth/verifyotp',
  changeSession: "/auth/changesession",
  logout: "/auth/logout",

  // admin
  admin: '/admin',

  // consulent
  consulent: '/consulent',

  // lead
  lead: '/lead',
  leadStatus: '/lead/status',
  leadQuestion: '/lead/question',
  leadClose: '/lead/close',
  leadlog: '/leadlog',
  reminder: '/lead/reminder',
  reminderConfirm: '/lead/reminder/confirm',
  leadUpload: '/lead/upload',
  leadAssign: '/lead/assign',
  leadAssignGroup: '/lead/group',
  leadRemarketing: '/lead/remarketing',
  addTrial: '/lead/trial',

  // module
  module: '/module',
  moduleAssign: '/module/transfer',

  // teacher
  teacher: "/teacher",

  available: "/available",
  availableDash: "/available/dash",

  //report
  reporter: "/reporter",
  report: "/report",

  // network
  network: "/network",
  networker: "/networker",
  networkerTransfer: "/networker/transfer",
  networkerCreate: "/networker/create",
  networkerReport: "/networker/report",
  networkerReporter: "/networker/reporter",
  networkerPay: "/networker/pay",


  // user
  user: "/user",
  resendSignup: "/user/resendtoken",
  signup: "/user/signup",

  // client
  clientDisconnect: "/client/disconnect",
  client: "/client",
  clientQuestion: '/client/question',

  // referent
  referent: "/referent",

  // course
  course: "/course",
  courseAvailable: "/available/course",

  // sell
  sell: "/sell",

  // streaming
  streaming: "/streaming",
  streamingRequest: "/streaming/request",
  streamingView: "/streaming/view",

  lesson: '/lesson',
  file: '/file',
  level: '/level',

  // payment
  payment: '/payment',
  paymentCheck: '/payment/check',

  // classroom
  classRoom: '/classroom',
  classRoomAttendanceDetail: '/classroom/attendance',
  classRoomListClient: '/classroom/list',
  classRoomInside: '/classroom/inside',
  classRoomPost: '/classroom/post',
  classRoomLesson: '/classroom/lesson',
  classRoomDateStart: '/classroom/start',
  classRoomDateEnd: '/classroom/end',
  classRoomPartecipants: '/classroom/partecipants',
  classroomWaiting: '/classroom/waiting',
  classroomDeploy: '/classroom/deploy',
  classroomComplete: '/classroom/complete',
  classroomRevert: '/classroom/revert',
  classroomInsert: '/classroom/insert',
  classroomRemove: '/classroom/remove',
  classroomConsulent: '/classroom/consulent',

  // group
  group: '/group',
  groupSell: '/group/insert/sell',
  groupWaiting: '/group/insert/waiting',
  groupAvailable: '/group/available',

  classroomTrial: '/classroomtrial',
  classroomTrialInsert: '/classroomtrial/insert',
  classroomTrialStart: '/classroomtrial/start',
  classroomTrialLink: '/classroomtrial/link',
  classroomTrialSign: '/classroomtrial/sign'
};



for (var i in route) {
  route[i] = server + route[i];
}
global.server = server;
global.frontend = frontendRoute;
global.productionMode = productionMode;
global.versionApp = versionApp;
global.emailAssistance = emailAssistance;
global.route = route;
module.export = route;
