import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText, Badge
} from 'reactstrap';

import { Utils } from "custom";
import {ClassRoomInsideAPI, ClassRoomTrialAPI, TeacherAPI} from "api";
import Select from "react-select";

const moment = require("moment")


class GestClassroomLesson extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                hour: (preset) ? moment(preset.date_hour).format("HH:mm") : null,
                date: (preset) ? moment(preset.date_hour).format("YYYY-MM-DD") : null,
                url_lesson: (preset) ? preset.url_lesson : null,
            },
            loading: false,
            errors: false
        };

    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {

            let form = this.state.form;
            let errors = this.state.errors;

            global.onSetModal({
                title: (this.props.preset) ? "MODIFICA LEZIONE" : "CREA LEZIONE",
                content: <div>
                    <Row>
                        <Col>
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Data*
                                </Label>
                                <Input type="date" value={form.date}
                                       min={moment(this.props.classroom.date_start).add(1, "days").toISOString().split("T")[0]}
                                       invalid={Boolean(errors.date)}
                                       onChange={(e) => this.changeForm('date', e.target.value)}/>
                                <FormFeedback>{errors.date}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Ora*
                                </Label><br />
                                {/*{allowedHour.map((slot, i) => {*/}
                                {/*    return <span key={i} style={{fontSize: 15}}><Badge className={(form.hour === slot) ? "cursor-pointer text-white" : "cursor-pointer text-dark"} color={(form.hour === slot) ? "primary" : "light"} onClick={() => this.changeForm("hour", slot)}><b>{slot}</b></Badge>&nbsp;</span>*/}
                                {/*})}*/}
                                <Input type="time" value={form.hour} invalid={Boolean(errors?.hour)} onChange={(e) => this.changeForm("hour", e.target.value)}/>
                                <FormFeedback>{errors?.hour}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Link Accesso Lezione
                                </Label><br />
                                <Input type="text" placeholder={"Esempio: https://us02web.zoom.us/j/123456789"} value={form.url_lesson} invalid={Boolean(errors?.url_lesson)} onChange={(e) => this.changeForm("url_lesson", e.target.value)}/>
                                <FormFeedback>{errors?.url_lesson}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div>,
                isOpen: true,
                toggle: false,
                size: 'lg',
                type: 'info',
                buttons: [
                    {label: 'SALVA', color: 'success', onClick: () => this.saveLesson()},
                    {
                        label: 'CHIUDI',
                        color: 'danger',
                        onClick: () => this.resetForm()
                    }
                ]
            })



    }

    resetForm() {
        let preset = this.props.preset;

        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                hour: (preset) ? moment(preset.date_hour).format("HH:mm") : null,
                date: (preset) ? moment(preset.date_hour).format("YYYY-MM-DD") : null,
                url_lesson: (preset) ? preset.url_lesson : null
            },
            errors: false
        })
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveLesson() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.hour === null)
            error.hour = "Il campo ora è obbligatorio";

        if (form.date === null)
            error.date = "Il campo data è obbligatorio";

        if (form.url_lesson !== '' && form.url_lesson !== null && !Utils.isValidUrl(form.url_lesson))
            error.url_lesson = "Il link non è corretto";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? ClassRoomInsideAPI.lessonAdd : ClassRoomInsideAPI.lessonEdit;

            let object = {
                id_classroom: this.props.id_classroom,
                id: form.id,
                date_hour: form.date + " " + form.hour + ":00",
                url_lesson: form.url_lesson
            }

            api(object).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Lezione creata' : 'Lezione modificata',
                    text: (add) ? 'La lezione è stata creata correttamente.' : 'La lezione è stata modificata correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione della lezione' : 'Si è verificato un errore durante la modifica della lezione',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-sm btn-success add-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA LEZIONE
                </button>}
            </React.Fragment>
        )
    }
}

export default GestClassroomLesson;
